module.exports = {
  bazooka: {
    25: '#FEF9FE',
    50: '#FEF5FE',
    75: '#FDEDFE',
    90: '#F9D6FC',
    100: '#F6C1FB',
    200: '#F3ACFA',
    300: '#EF8DF8',
    400: '#E386EC',
  },

  gumdrop: {
    25: '#F8FCFF',
    50: '#F1F8FF',
    75: '#D9EFFF',
    90: '#B5E1FF',
    100: '#90D2FF',
    200: '#5FBFFF',
    300: '#1AA3FF',
    400: '#1894E8',
    500: '#007ACD',
  },

  apple: {
    25: '#F8FFFD',
    50: '#EEFFFA',
    75: '#E2FDF2',
    90: '#B0F7D3',
    100: '#7EF1B3',
    200: '#62E2A4',
    300: '#1ED67D',
    400: '#26BC62',
  },

  redhot: {
    25: '#FFFAFB',
    50: '#FFF6F8',
    75: '#FFEAEF',
    90: '#FFDEE6',
    100: '#FFD2DD',
    200: '#FF899E',
    300: '#FF6678',
    400: '#F05C6B',
    500: '#D42C3D',
    frosting: {
      primary: '#F4486E',
      secondary: '#F470B3',
    },
  },

  julius: {
    25: '#FFFCF7',
    50: '#FFF7EA',
    75: '#FFF5DF',
    90: '#FEF2D4',
    100: '#FCE7A7',
    200: '#FFCD83',
    300: '#FFA876',
    400: '#FF8368',
  },

  licorice: {
    25: '#F8F8FA',
    50: '#F1F0F5',
    75: '#E1DFE8',
    100: '#C7C5D1',
    200: '#A7A3B5',
    300: '#8A849C',
    400: '#6B6580',
    500: '#4E4763',
    600: '#3D3652',
    700: '#29233B',
    800: '#1F1A30',
    900: '#111827',
  },

  jelly: {
    25: '#FCFAFF',
    50: '#F6F2FF',
    100: '#CCB6FC',
    200: '#B090F5',
    300: '#9F72FF',
    400: '#8656EF',
    frosting: {
      primary: '#F1EBFF',
      secondary: '#DDEFFF',
    },
  },
  icee: {
    25: '#F1FBFF',
    50: '#E1F7FE',
    75: '#D6F4FE',
    90: '#BAEDFD',
    100: '#9FE6FC',
    200: '#83DEFB',
    300: '#68D7FA',
  },
  transparent: 'transparent',
  white: '#FFF',
  black: '#000',
  background: '#FFF',
}
